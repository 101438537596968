<template>
  <div class="retrieve-password">
    <div class="content">
      <router-link to="/login" style="font-size: 16px; color: #0076c2; cursor: pointer">返回上一页</router-link>
      <div style="font-size: 20px; color: #272727; margin: 42px 0 30px">找回密码</div>

      <div class="password-box">
        <div>
          <el-steps :active="stepActive" simple style="cursor: pointer">
            <el-step title="1.输入账号" @click.native="backStep(0)" />
            <el-step title="2.输入验证码" @click.native="backStep(1)"></el-step>
            <el-step title="3.修改密码" @click.native="backStep(2)" />
            <el-step title="4.完成修改" @click.native="backStep(3)" />
          </el-steps>
        </div>
        <ul>
          <li class="step-one" v-if="stepActive == 0">
            <div class="input-box">
              <div class="input-title">
                <span class="error-text" v-if="errorText.stepOne">账号不存在，请重新输入</span>
              </div>
              <el-form :label-position="right" label-width="100px">
                <el-form-item label="请输入账号：">
                  <el-input v-model.trim="userInfo.userAccount"></el-input>
                </el-form-item>
              </el-form>
              <div ref="oneBtn" class="one-btn" @click="oneNext">下一步</div>
            </div>
          </li>
          <li class="step-two" v-if="stepActive == 1">
            <div class="input-box">
              <el-form :label-position="right" label-width="100px">
                <el-form-item label="账号：">
                  <span>{{ userInfo.userAccount }}</span>
                </el-form-item>
                <el-form-item :label="this.codeType == 1 ? '手机号：' : '邮箱：'">
                  <span>
                    {{
                    this.codeType == 1 ? userInfo.phone : userInfo.mail
                    }}
                  </span>
                </el-form-item>
                <el-form-item label="验证方式：">
                  <el-radio v-model="codeType" label="1">手机号验证</el-radio>
                  <el-radio v-model="codeType" label="2">邮箱验证</el-radio>
                </el-form-item>

                <el-form-item label="验证码：">
                  <span
                    class="error-text"
                    v-if="errorText.stepTwo"
                    style="position: absolute; top: -30px"
                  >验证码错误</span>
                  <el-input v-model.trim="code" @focus="errorText.stepTwo = false">
                    <el-button slot="append" @click="sendCode" :disabled="countdown < 60">
                      发送验证码&nbsp;
                      <span v-if="countdown !== 60">
                        {{
                        countdown
                        }}
                      </span>
                    </el-button>
                  </el-input>
                </el-form-item>

                <el-form-item>
                  <div class="one-btn" @click="twoNext">下一步</div>
                </el-form-item>
              </el-form>
              <div class="backStep" @click="stepActive--">上一步</div>
            </div>
          </li>
          <li class="step-three" v-if="stepActive == 2">
            <div class="input-box">
              <el-form :label-position="right" label-width="100px">
                <el-form-item label="新密码：">
                  <el-input
                    type="password"
                    v-model.trim="newPassword"
                    @focus="errorText.stepThree = false"
                  />
                </el-form-item>

                <br />
                <el-form-item label="重复密码：">
                  <span
                    class="error-text"
                    v-if="errorText.stepThree"
                    style="position:absolute;top:-35px;"
                  >账号必须相同</span>
                  <el-input
                    type="password"
                    v-model.trim="newPasswordAgain"
                    @focus="errorText.stepThree = false"
                  />
                </el-form-item>
              </el-form>
              <div class="one-btn" @click="threeNext">下一步</div>
              <br />
              <div class="backStep" @click="stepActive--">上一步</div>
            </div>
          </li>
          <li class="step-four" v-if="stepActive == 3">
            <div class="input-box">
              <div class="done">
                <div>
                  <img src="" />
                </div>
                <div class="text">密码修改完成</div>
              </div>
              <div class="one-btn">
                <router-link to="/login">直接登录</router-link>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {findPasswordAccount,findPassWordGetCode,findPassWordCheckCode,findPassWordChange} from '@/api/Register.js'
export default {
  data() {
    return {
      stepActive: 0, // 步骤条状态
      stepMaxBack: 0,
      userInfo: {
        userAccount: "",
        key: "",
        mail: "",
        phone: ""
      },
      code: "",
      codeType: "1",
      countdown: 60,
      newPassword: "",
      newPasswordAgain: "",
      errorText: {
        stepOne: false,
        stepTwo: false,
        stepThree: false
      }
    };
  },
  created() {
    this.keyupEnter();
  },
  methods: {
    async oneNext() {
      if (this.userInfo.userAccount.length <= 0) {
        this.$message.error("账号不能为空");
        return false;
      }

    //   let res = await this.$axios.get("/api/user/getPhoneByAccount", {
    //     params: { userAccount: this.userInfo.userAccount }
    //   });
      let res = await findPasswordAccount(this.userInfo.userAccount)

      if (res.data.code != 200) {
        this.errorText.stepOne = true;
        return;
      }
      let { userMail, userPhone } = res.data.data;
      this.userInfo.mail = userMail;
      this.userInfo.phone = userPhone;

      this.stepActive++;
      this.stepMaxBack = this.stepActive;
    },
    async twoNext() {
      if (this.code.length <= 0) {
        this.$message.error("验证码不能为空");
        return false;
      }

      // let res = await this.$axios.post("/api/user/verificationCodeCheck", {
      //   userAccount: this.userInfo.userAccount,
      //   code: this.code
      // });
      let res = await findPassWordCheckCode({
        code: this.code,
        userAccount: this.userInfo.userAccount,
        userPhone:this.userInfo.phone
      })


      if (res.data.code != 200) {
        this.errorText.stepTwo = true;
        return;
      }

      this.userInfo.key = res.data.data;
      this.stepActive++;
      this.stepMaxBack = this.stepActive;
    },
    async threeNext() {
      if (this.newPassword.length <= 0) {
        this.$message.error("不能为空");
        return false;
      }
      if (this.newPassword !== this.newPasswordAgain) {
        this.errorText.stepThree = true;
        return;
      }
      // let res = await this.$axios.post("/api/user/findBackPassword", {
      //   key: this.userInfo.key,
      //   newPassword: this.newPassword,
      //   userAccount: this.userInfo.userAccount
      // });

      let res = await findPassWordChange({
        newPassword:this.md5(this.newPassword),
        userPhone:this.userInfo.phone
      })

      if (res.data.code != 200) {
        this.$message.error("密码修改失败，请重新再试");
        return;
      }
      this.stepActive++;
      this.stepMaxBack = this.stepActive;
    },
    backStep(stepActive) {
      if (stepActive <= this.stepMaxBack) {
        this.stepActive = stepActive;
      } else {
        this.$message.warning(`你还未到第 ${stepActive + 1} 步`);
      }
    },
    async sendCode() {
      this.countdown = 60;

      let countdown = setInterval(() => {
        this.countdown--;

        if (this.countdown <= 0) {
          this.countdown = 60;
          clearInterval(countdown);
        }
      }, 1000);
      this.errorText.stepTwo = false;

      // let res = await this.$axios.post("/api/user/verificationCodeByPhone", {
      //   type: this.codeType,
      //   userAccount: this.userInfo.userAccount
      // });
      let res = await findPassWordGetCode({
        type: this.codeType,
        userMail:this.userInfo.mail,
        userPhone:this.userInfo.phone
        })

      if (res.data.code != 200) {
        this.$message.error("验证码发送失败  请重试 !");
        return;
      }
    },
    keyupEnter() {
      document.onkeydown = e => {
        if (e.keyCode === 13) {
          if (this.stepActive == 0) {
            this.oneNext();
          } else if (this.stepActive == 1) {
            this.twoNext();
          } else if (this.stepActive == 2) {
            this.threeNext();
          }
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
 .retrieve-password {
  width: 100%;
  height: 100%;
  font-size: 14px;
  // background: url('../assets/img/logoImg.jpg');
  background-color: rgb(240, 240, 240);
  .content {
    width: 1000px;
    height: 500px;
    margin: 0 auto;
    padding-top: 148px;

    .password-box {
      height: 560px;
      padding: 52px;
      background-color: #fff;

      .input-box {
        width: 424px;
        margin: 100px auto 0;
        .error-text {
          color: red;
        }
        .input-title {
          color: #343434;
          margin: 0px 0 15px;
          font-size: 14px;
        }
        .one-btn {
          height: 40px;
          line-height: 40px;
          margin-top: 39px;
          color: #fff;
          text-align: center;
          font-size: 18px;
          background-color: #0076c2;
          border-radius: 4px;
          cursor: pointer;
        }
        // .select-code-way {
        //   // text-align: center;
        // }
        .done {
          position: relative;
          width: 190px;
          height: 35px;
          margin: 0 auto;
          div {
            // width: 82px;
            position: absolute;
            top: 0;
            height: 32px;
            display: inline-block;
          }
          .text {
            left: 35px;
            line-height: 32px;
            color: #272727;
            font-size: 20px;
          }
        }
        .backStep {
          text-align: right;
          color: #0076c2;
          cursor: pointer;
        }
      }
      .step-two,
      .step-three {
        .input-box {
          margin: 50px auto 0;
        }
      }
    }
    .password-box .el-step__head {
      display: none;
    }
  }
}
</style>