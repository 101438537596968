import request from '@/utils/request'

export function findPasswordAccount(userAccount) {
    return request({
        url:'/api/user/findPasswordAccount',
        data:{
            userAccount
        },
        method:'post'
    });
}

export function findPassWordGetCode(data) {
    return request({
        url:'/api/user/findPassWordGetCode',
        data,
        method:'post'
    });
}

export function findPassWordCheckCode(data) {
    return request({
        url:'/api/user/findPassWordCheckCode',
        method:'post',
        data
    });
}

export function findPassWordChange(data) {
    return request({
        url:'/api/user/findPassWordChange',
        method:'post',
        data
    });
}